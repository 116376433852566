"use client";

import { login, signup } from "@/app/login/actions";
export const LoginByEmailForm: React.FC<{
  next?: string | null;
}> = ({
  next
}) => {
  return <form className="w-full" data-sentry-component="LoginByEmailForm" data-sentry-source-file="email.tsx">
      <label htmlFor="email" className="block text-sm font-medium text-gray-700">
        Email:
      </label>
      <input id="email" name="email" type="email" required className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />

      <label htmlFor="password" className="block text-sm font-medium text-gray-700 mt-4">
        Password:
      </label>
      <input id="password" name="password" type="password" required className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />

      <input type="hidden" name="next" value={next || ""} />

      <div className="mt-6 flex justify-between">
        <button formAction={login} className="w-full mr-2 py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
          Log in
        </button>
        <button formAction={signup} className="w-full ml-2 py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500">
          Sign up
        </button>
      </div>
    </form>;
};