'use client';

import { Github } from 'lucide-react';
import { MouseEventHandler, useCallback } from 'react';
import { Button } from '@/components/ui/button';
import { cn } from '@/lib/utils';
import { useAmplitudeContext } from '@/utils/amplitude';
import { useSupabase } from '@/utils/supabase/client';
export const LoginByGithubBtn: React.FC<{
  next?: string | null;
  className?: string;
}> = ({
  next,
  className
}) => {
  const supabase = useSupabase();
  const {
    trackAmplitudeEvent
  } = useAmplitudeContext();
  const handleLoginByGithub: MouseEventHandler<HTMLButtonElement> = useCallback(async e => {
    e.preventDefault();
    const redirectToURL = new URL(`${window.location.origin}/auth/callback`);
    if (next) {
      redirectToURL.searchParams.set('next', next);
    }
    try {
      const {
        data,
        error
      } = await supabase.auth.signInWithOAuth({
        provider: 'github',
        options: {
          redirectTo: redirectToURL.toString()
        }
      });
      if (error) throw error;
    } catch (error) {
      console.error('Error during GitHub sign-in:', error);
    }
  }, [supabase, next, trackAmplitudeEvent]);
  return <Button className={cn('w-full', className)} variant="outline" onClick={handleLoginByGithub} data-sentry-element="Button" data-sentry-component="LoginByGithubBtn" data-sentry-source-file="github.tsx">
      <Github className="mr-2 h-4 w-4" data-sentry-element="Github" data-sentry-source-file="github.tsx" />
      Continue with GitHub
    </Button>;
};